//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import answer from '@/views/pages/answer';
export default {
  components: {
    answer: answer
  },
  props: {},
  data: function data() {
    return {
      dialogVisible: false,
      questionId: null,
      title: ''
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(qid, title, finish) {
      this.title = title;
      this.questionId = qid;
      this.dialogVisible = true;
      this.finish = finish;
      this.$refs.answer.reloadData();
    },
    onClose: function onClose() {
      this.finish();
    }
  }
};