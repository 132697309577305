import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/zq/Documents/Projects/2021/sxGame/zyBbt/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/Users/zq/Documents/Projects/2021/sxGame/zyBbt/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { mapState } from 'vuex';
import menuMixin from '../mixin/menu';
import { elMenuItem, elSubmenu } from '../libs/util.menu';
import BScroll from 'better-scroll';
export default {
  name: 'd2-layout-header-aside-menu-side',
  mixins: [menuMixin],
  render: function render(createElement) {
    var _this = this;

    return createElement('div', {
      attrs: {
        class: 'd2-layout-header-aside-menu-side'
      }
    }, [createElement('el-menu', {
      props: {
        collapse: this.asideCollapse,
        uniqueOpened: true,
        defaultActive: this.active
      },
      ref: 'menu',
      on: {
        select: this.handleMenuSelect
      }
    }, this.aside.map(function (menu) {
      return (menu.children === undefined ? elMenuItem : elSubmenu).call(_this, createElement, menu);
    }))].concat(_toConsumableArray(this.aside.length === 0 && !this.asideCollapse ? [createElement('div', {
      attrs: {
        class: 'd2-layout-header-aside-menu-empty',
        flex: 'dir:top main:center cross:center'
      }
    }, [createElement('d2-icon', {
      props: {
        name: 'inbox'
      }
    }), createElement('span', {}, '没有侧栏菜单')])] : [])));
  },
  data: function data() {
    return {
      active: '',
      asideHeight: 300,
      BS: null
    };
  },
  computed: _objectSpread({}, mapState('d2admin/menu', ['aside', 'asideCollapse'])),
  watch: {
    // 折叠和展开菜单的时候销毁 better scroll
    asideCollapse: function asideCollapse(val) {
      var _this2 = this;

      this.scrollDestroy();
      setTimeout(function () {
        _this2.scrollInit();
      }, 500);
    },
    // 监听路由 控制侧边栏激活状态
    '$route.fullPath': {
      handler: function handler(value) {
        this.active = value;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.scrollInit();
  },
  beforeDestroy: function beforeDestroy() {
    this.scrollDestroy();
  },
  methods: {
    scrollInit: function scrollInit() {
      this.BS = new BScroll(this.$el, {
        mouseWheel: true,
        click: true // 如果你愿意可以打开显示滚动条
        // scrollbar: {
        //   fade: true,
        //   interactive: false
        // }

      });
    },
    scrollDestroy: function scrollDestroy() {
      // https://github.com/d2-projects/d2-admin/issues/75
      try {
        this.BS.destroy();
      } catch (e) {
        delete this.BS;
        this.BS = null;
      }
    }
  }
};