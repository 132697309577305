import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { categoryAdd, categoryDel, categoryList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "分类ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "分类名称CN",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "cate_name_cn"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "分类名称EN",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "120",
          "prop": "cate_name_en"
        }, {
          "add": 1,
          "defaultValue": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "排序",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "sort"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "图标",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "image",
          "width": "100",
          "prop": "pic",
          "desc": "建议尺寸640x320 不超过256kb",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 0,
            "key": "pic",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否推荐",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "80",
          "prop": "is_show"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "添加时间",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "add_time"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "类型",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "defaultValue": 1,
          "prop": "type"
        }],
        "tableName": "t_category",
        "tableRadioProps": {},
        "tableSelectProps": {
          "is_show": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 1,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "type": {
            "multiple": 0,
            "data": [{
              "key": 1,
              "value": "BTT"
            }, {
              "key": 2,
              "value": "FTT"
            }],
            "remote": false,
            "id": 2,
            "relation": false,
            "relationProps": []
          }
        },
        "tableSwitchProps": {},
        "title": "题库分类",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      categoryList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      categoryAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      categoryDel({
        id: ids
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};