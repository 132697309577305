import request from '@/plugin/axios';
import setting from '@/setting';
var hostPath = setting.appApi;

var pos = function pos(host, action, data) {
  if (!data) {
    data = {};
  }

  return request({
    url: host + action,
    method: 'post',
    data: data
  });
};

export function accountCodeBind(data) {
  return pos(hostPath.base, 'tw/bind', data);
}
export function accountLogin(data) {
  return pos(hostPath.base, 'account/login', data);
}
export function accountList(data) {
  return pos(hostPath.base, 'account', data);
}
export function accountAdd(data) {
  return pos(hostPath.base, 'account/handle', data);
}
export function accountDel(data) {
  return pos(hostPath.base, 'account/delete', data);
}
export function menuList(data) {
  return pos(hostPath.base, 'system/menu', data);
}
export function menuBatch(data) {
  return pos(hostPath.base, 'system/menu/batch/handle', data);
}
export function menuAdd(data) {
  return pos(hostPath.base, 'system/menu/handle', data);
}
export function menuDel(data) {
  return pos(hostPath.base, 'system/menu/delete', data);
}
export function roleList(data) {
  return pos(hostPath.base, 'account/roles', data);
}
export function roleAdd(data) {
  return pos(hostPath.base, 'account/roles/handle', data);
}
export function roleDel(data) {
  return pos(hostPath.base, 'account/roles/delete', data);
}
export function categoryList(data) {
  //新闻
  return pos(hostPath.base, 'category', data);
}
export function categoryAdd(data) {
  //新闻
  return pos(hostPath.base, 'category/handle', data);
}
export function categoryDel(data) {
  //新闻
  return pos(hostPath.base, 'category/delete', data);
}
export function answerList(data) {
  //新闻
  return pos(hostPath.base, 'answer', data);
}
export function answerAdd(data) {
  //新闻
  return pos(hostPath.base, 'answer/handle', data);
}
export function answerDel(data) {
  //新闻
  return pos(hostPath.base, 'answer/delete', data);
}
export function questionList(data) {
  //新闻
  return pos(hostPath.base, 'question', data);
}
export function questionAdd(data) {
  //新闻
  return pos(hostPath.base, 'question/handle', data);
}
export function questionDel(data) {
  //新闻
  return pos(hostPath.base, 'question/delete', data);
}
export function userList(data) {
  //新闻
  return pos(hostPath.base, 'user', data);
}
export function userAdd(data) {
  //新闻
  return pos(hostPath.base, 'user/handle', data);
}
export function userDel(data) {
  //新闻
  return pos(hostPath.base, 'user/delete', data);
}