var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "60%",
        title: "题目：" + _vm.title,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "div",
        { staticStyle: { "background-color": "#f6f6f6" } },
        [
          _c("answer", {
            ref: "answer",
            attrs: { "question-id": _vm.questionId }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }