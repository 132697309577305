import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { userList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "is_vip",
              "value": "1"
            }],
            "title": "是",
            "type": "button"
          }, {
            "field": [{
              "key": "is_vip",
              "value": "0"
            }],
            "title": "否",
            "type": "button"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "是否VIP",
          "type": 0,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "UID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 0,
          "prop": "uid"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "",
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": 0,
          "prop": "token"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "是否VIP",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": 0,
          "prop": "is_vip"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "添加日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 0,
          "prop": "add_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "上次登录",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 0,
          "prop": "last_login"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "邮箱",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 0,
          "prop": "email"
        }],
        "tableName": "t_user",
        "tableRadioProps": {},
        "tableSelectProps": {
          "is_vip": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "用户",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      userList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};